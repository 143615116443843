import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { Helmet } from 'react-helmet';

import { grid, thankYouBox, isVisible, notVisible } from './account-activation.module.scss';
import { ISection } from '../../models/section.model';
import { useUser } from '../../hooks/use-user';

import Section from '../hoc/section';
import ThankYouBox from '../molecules/thank-you-box';
import Loader from '../atoms/loader';

export interface IAccountActivationProps {
    className?: string;
    TitleTag?: React.ElementType;
    section: ISection;
}

const AccountActivation: React.FC<IAccountActivationProps> = ({
    className = '',
    TitleTag,
    section,
}) => {
    const [title, description] = section.content.texts;
    const [button] = section.content.buttons;
    const { sectionId, css, style } = section;

    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const token = urlParams.get('token');

    const userState = useUser();

    useEffect(() => {
        if (token) {
            userState.verify.fetch(token);
        }
        if (userState.verify.isError) {
            navigate('/');
        }
    }, [token, userState.verify]);

    if (userState.verify.isError) {
        return <Helmet bodyAttributes={{ class: notVisible }} />;
    }

    return (
        <Section
            sectionId={sectionId}
            className={className}
            classes={{
                container: grid,
            }}
            TitleTag={TitleTag}
            css={css}
            style={style}
        >
            <ThankYouBox
                data={{ title: title || '', description: description || '', button: button }}
                className={`${thankYouBox} ${userState.verify.isSuccess ? isVisible : ''}`}
            />
            {userState.verify.isLoading && <Loader width={'full'} />}
        </Section>
    );
};

export default AccountActivation;
